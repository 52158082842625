import { createApp } from 'vue';
import { createMemoryHistory, createRouter } from 'vue-router';
import App from "./App.vue";
import Routes from './routes.js';
import { createI18n } from 'vue-i18n';
import "../../static/styles/stylesheet.scss";

import en from '../../static/languages/en.json';
import am from '../../static/languages/am.json';

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en, am }
});


import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import axios from 'axios';

const HttpErrorForbidden = 403;

const vuetify = createVuetify({
  components,
  directives,
})

const router = createRouter({
  history: createMemoryHistory(),
  routes: Routes
});

const app = createApp(App)
app.use(vuetify)
app.use(router)

function reloadPage() {
  return new Promise(resolve => {
    window.location.reload();
    resolve();
  });
}

let reloading = false;

axios.interceptors.response.use(
  function (response) {
    if (response.data instanceof Object && 'access_token' in response.data) {
      update_access_token(response.data)
    }
    return response;
  },
  function (error) {
    if (error.response && error.response.status === HttpErrorForbidden) {
      this.logged = false;
      if (reloading === true) {
        return Promise.reject(error);
      } else {
        reloading = true;
        reloadPage()
          .then(() => { })
          .catch(err => { alert(err) });
      }
    }
    return Promise.reject(error.response.data);
  });

function update_access_token(data) {
  if ('access_token' in data) {
    localStorage.setItem('access_token', data['access_token']);
    axios.defaults.headers.common['Authorization'] = data['access_token'];
  }
}

app.use(i18n);
app.mount('#app')
