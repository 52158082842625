export default [
  {
    path: '/',
    name: 'WAV',
    component: () => import('../components/WAV.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/Login.vue'),
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('../components/SignUp.vue'),
  },
  {
    path: '/workspace/:username',
    name: 'Workspace',
    component: () => import('../components/Workspace.vue'),
    props: true,
  },
  {
    path: '/change_password',
    name: 'ChangePassword',
    component: () => import('../components/ChangePassword.vue'),
  },
  {
    path: '/forgot_password',
    name: 'ForgotPassword',
    component: () => import('../components/ForgotPassword.vue'),
  },
  {
    path: '/confirm_mail',
    name: 'ConfirmMail',
    component: () => import('../components/ConfirmMail.vue'),
  },
  {
    path: '/check_mail',
    name: 'CheckMail',
    component: () => import('../components/CheckMail.vue'),
  },
  {
    path: '/profile_settings',
    name: 'ProfileSettings',
    component: () => import('../components/ProfileSettings.vue'),
  },


];
